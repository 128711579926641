import html2pdf from 'html2pdf.js';

export const generatePdfFromElement = async (element) => {
  const opt = {
    margin: [0, 0, 0, 0],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.9 },
    // html2canvas: { scale: 2, letterRendering: true, dpi: 300 },
    html2canvas: { scale: 2, letterRendering: true, dpi: 300 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['css', 'legacy', 'avoid-all'] }
  };

  const pdfSrc = await html2pdf()
    .set(opt)
    .from(element)
    .outputPdf('datauristring');

  return pdfSrc;
};

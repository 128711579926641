<template>
  <div
    id="order-document"
    class="order-document"
    dir="rtl"
    style="clip-path: inset(0 100% 0 0); position: relative"
  >
    <section class="header">
      <div class="customer-info">
        <div class="font-weight-600 font-size-20" style="margin-bottom: 5px">
          {{
            $t('customer.orderDocumentBuilder.fromCustomer', [
              order.account.companyName
            ])
          }}
        </div>
        <div class="font-size-16">
          {{
            `${$t('customer.orderDocumentBuilder.vendorBusinessId')}: ${
              order.account.businessId
            }`
          }}
        </div>
        <!-- <div class="font-size-16">
          {{
            `${$t('customer.orderDocumentBuilder.phoneNumber')}: ${
              order.account.contactPersonPhone
            }`
          }}
        </div> -->
        <div class="font-size-16">
          {{
            `${$t('customer.orderDocumentBuilder.issueDate')}: ${
              order && order.issueDate
                ? format(new Date(order.issueDate), 'dd/MM/yyyy')
                : ''
            }`
          }}
        </div>
      </div>
      <img class="logo" :src="logoUri" />
    </section>
    <section class="vendor-info">
      <div class="font-weight-600 font-size-20" style="margin-bottom: 5px">
        {{
          $t('customer.orderDocumentBuilder.forVendor', [
            selectedVendor?.businessName || order?.vendor?.businessName || ''
          ])
        }}
      </div>
      <div class="font-size-16">
        {{
          `${$t('customer.orderDocumentBuilder.vendorBusinessId')}: ${
            selectedVendor?._id || order?.vendor?._id || ''
          }`
        }}
      </div>
      <div class="font-size-16">
        {{
          `${$t('customer.orderDocumentBuilder.vendorContactName')}: ${
            selectedVendor?.contactPersonName ||
            order?.vendor?.contaccontactPersonNametName ||
            ''
          }`
        }}
      </div>
      <div class="font-size-16">
        {{
          `${$t('customer.orderDocumentBuilder.vendorContactPhone')}: ${
            selectedVendor?.contactPhoneNumber ||
            order?.vendor?.contactPhoneNumber ||
            ''
          }`
        }}
      </div>
      <div class="font-size-16">
        {{
          `${$t('customer.orderDocumentBuilder.vendorContactEmail')}: ${
            selectedVendor?.contactEmail || order?.vendor?.contactEmail || ''
          }`
        }}
      </div>
      <div class="font-size-16">
        {{
          `${$t('customer.orderDocumentBuilder.vendorAddress')}: ${
            selectedVendor?.address || order?.vendor?.address || ''
          }`
        }}
      </div>
    </section>
    <section class="order-info table-wrapper">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        "
      >
        <h1>
          {{
            $t('customer.orderDocumentBuilder.orderNumber', [order.orderNumber])
          }}
        </h1>
        <div class="font-weight-600 font-size-20" style="color: #0d2c66">
          {{ order.title }}
        </div>
      </div>

      <table style="border: 1px solid #0d2c66" v-if="hasItems">
        <tr>
          <th style="width: 10%">
            {{ $t('customer.orderDocumentBuilder.itemNumber') }}
          </th>
          <th style="width: 15%">
            {{ $t('customer.orderDocumentBuilder.itemName') }}
          </th>
          <th style="width: 15%">
            {{ $t('customer.orderDocumentBuilder.supplyDate') }}
          </th>
          <th style="width: 10%">
            {{ $t('customer.orderDocumentBuilder.itemUnitOfMeasure') }}
          </th>
          <th style="width: 10%">
            {{ $t('customer.orderDocumentBuilder.quantity') }}
          </th>
          <th style="width: 10%">
            {{ $t('customer.orderDocumentBuilder.pricePerUnit') }}
          </th>
          <th style="width: 15%">
            {{ $t('customer.orderDocumentBuilder.total') }}
          </th>
        </tr>
        <tr v-for="(item, index) in order.orderItems" :key="`item_${index}`">
          <td>{{ item.catalogItem.itemCode }}</td>
          <td>{{ item.catalogItem.itemName }}</td>
          <td>{{ format(new Date(item.supplyDate), 'dd/MM/yyyy') }}</td>
          <td>{{ item.catalogItem.itemUnitOfMeasureText }}</td>
          <td>{{ item.quantity }}</td>
          <td>
            <span v-if="order.currency"
              >{{
                item.pricePerUnit.toLocaleString(undefined, {
                  style: 'currency',
                  currency: order.currency || ''
                })
              }}
            </span>
          </td>
          <td>
            <span v-if="order.currency">
              {{
                (item.pricePerUnit * item.quantity).toLocaleString(undefined, {
                  style: 'currency',
                  currency: order.currency || ''
                })
              }}</span
            >
          </td>
        </tr>
      </table>
    </section>

    <section class="summary">
      <div class="table-wrapper" style="flex-grow: 1" v-if="!hasItems">
        <table style="border: 1px solid #0d2c66; width: 100%">
          <tr>
            <th>
              {{ $t('customer.orderDocumentBuilder.commentAndPayment') }}
            </th>
          </tr>
          <tr>
            <td
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                line-height: 25px;
              "
            >
              {{ order.description }}
            </td>
          </tr>
        </table>
      </div>
      <span />
      <div style="display: flex; flex-direction: column; gap: 2px">
        <div
          style="display: flex; gap: 1px"
          v-for="item in summaryItems"
          :key="item.title"
        >
          <div
            style="
              background-color: #d9ecf4;
              padding: 5px 0;
              width: 180px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
              font-size: 18px;
            "
          >
            {{ item.title }}
          </div>
          <div
            style="
              background-color: #d9ecf4;
              padding: 5px 0;
              width: 137px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </section>
    <section class="comments" v-if="hasItems">
      <div class="table-wrapper" style="flex-grow: 1">
        <table style="border: 1px solid #0d2c66; width: 100%">
          <tr>
            <th>
              {{ $t('customer.orderDocumentBuilder.commentAndPayment') }}
            </th>
          </tr>
          <tr>
            <td
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                line-height: 25px;
              "
            >
              {{ order.description }}
            </td>
          </tr>
        </table>
      </div>
    </section>
    <div style="padding: 20px; bottom: 0; z-index: 1000; margin-top: 40px">
      {{ $t('customer.orderDocumentBuilder.orderPoweredByCorz') }}
    </div>
  </div>
</template>

<script>
import image from '@/assets/corz.png';
import { format } from 'date-fns';
export default {
  name: 'OrderDocumentBuilder',
  props: {
    order: {
      type: Object,
      required: true
    },
    selectedVendor: {
      type: Object
    },
    logoUri: {
      type: String
    }
  },

  created() {},
  computed: {
    netAmount() {
      return this.order?.currency && this.order?.netAmount
        ? this.order.netAmount.toLocaleString(undefined, {
            style: 'currency',
            currency: this.order.currency
          })
        : '';
    },
    branding() {
      return {
        backgroundColor: '#d9ecf4',
        borderColor: '#0d2c66',
        primaryTextColor: '#212121',
        secondaryTextColor: '#0d2c66'
      };
    },
    hasItems() {
      return this.order?.orderItems?.length > 0;
    }
  },
  data() {
    return {
      image,
      items: [],
      orderDetailFields: [
        {
          fields: [
            {
              title: this.$t('customer.orderDocumentBuilder.issueDate'),
              value: this.order?.issueDate
                ? format(new Date(this.order.issueDate), 'dd/MM/yyyy')
                : ''
            },
            {
              title: this.$t('customer.orderDocumentBuilder.vendorName'),
              value:
                this.selectedVendor?.businessName ||
                this.order?.vendor?.businessName
            }
          ]
        },
        {
          fields: [
            {
              title: this.$t('customer.orderDocumentBuilder.orderNumberTitle'),
              value: this.order?.orderNumber
            },
            {
              title: this.$t('customer.orderDocumentBuilder.vendorBusinessId'),
              value: this.selectedVendor?._id || this.order?.vendor?._id
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: this.$t('customer.orderDocumentBuilder.vendorContactName'),
              value:
                this.selectedVendor?.contactPersonName ||
                this.order?.vendor?.contaccontactPersonNametName
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: this.$t(
                'customer.orderDocumentBuilder.vendorContactPhone'
              ),
              value:
                this.selectedVendor?.contactPhoneNumber ||
                this.order?.vendor?.contactPhoneNumber
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: this.$t(
                'customer.orderDocumentBuilder.vendorContactEmail'
              ),
              value:
                this.selectedVendor?.contactEmail ||
                this.order?.vendor?.contactEmail
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: this.$t('customer.orderDocumentBuilder.vendorAddress'),
              value: this.selectedVendor?.address || this.order?.vendor?.address
            }
          ]
        }
      ],
      summaryItems: [
        {
          title: this.$t('customer.orderDocumentBuilder.total'),
          value:
            this.order?.currency && this.order?.netAmount
              ? this.order.netAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        },
        {
          title: this.$t('customer.orderDocumentBuilder.vat'),
          value:
            this.order?.currency && this.order?.vatAmount !== undefined
              ? this.order.vatAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        },
        {
          title: this.$t('customer.orderDocumentBuilder.totalAmount'),
          value:
            this.order?.currency && this.order?.totalAmount
              ? this.order.totalAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        }
      ]
    };
  },
  methods: {
    format
  }
};
</script>

<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
body {
  margin: 0;
}

.header {
  background-color: #d9ecf4;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.vendor-info {
  padding: 20px;
  color: #0d2c66;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.customer-info {
  color: #0d2c66;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.order-document {
  font-family: 'Rubik', sans-serif;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 400;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

h1 {
  font-size: 30px !important;
}

h2 {
  font-size: 20px;
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.top .title {
  font-weight: 600;
}

.logo {
  border-radius: 50%;
  border: 2px solid #fed1f4;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.fields {
  display: flex;
  align-items: center;
  padding: 30px;
  column-gap: 20px;
  row-gap: 5px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.field-wrapper {
  display: flex;
  align-items: center;
}

.field {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.field-input {
  background-color: #fed1f4;
  padding: 0;
  font-size: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.field-name {
  width: 150px;
}

.field-value {
  width: 200px;
}

.table-wrapper {
}

.table-wrapper h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.table-wrapper table tr th {
  background-color: #d9ecf4;
  padding: 15px;
  border: 1.5px solid #0d2c66;
  font-weight: 600;
  text-align: start;
  font-size: 18px;
  color: #0d2c66;
}

.table-wrapper table tr td {
  padding: 10px;
  border: 1.5px solid #0d2c66;
}

.table-wrapper table tr td .title {
  font-weight: 600;
  margin-left: 10px;
}

.table-wrapper table tr td .value {
  font-weight: 400;
}

table {
  border-collapse: collapse;
}

.summary {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
}

.comments {
  padding: 20px;
}

.summary-items {
  display: flex;
  flex-direction: column;
}

.summary-items .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.summary-items .item .title {
  font-weight: 600;
}

.summary-items .item .value {
  // border: 1px solid black;
  padding: 5px;
  // min-width: 120px;
  font-weight: 400;
}
</style>
